/* eslint no-extend-native: 0 */
// core-js comes with Next.js. So, you can import it like below
import includes from 'core-js/library/fn/string/virtual/includes';
import from from 'core-js/library/fn/array/from';
import repeat from 'core-js/library/fn/string/virtual/repeat';
import assign from 'core-js/library/fn/object/assign';
import set from 'core-js/es6/set';
import map from 'core-js/es6/map';
import 'core-js/es6/map';
import 'core-js/es6/function';
import 'intersection-observer';
import 'intl-pluralrules';
// Add your polyfills
// This files runs at the very beginning (even before React and Next.js core)
// console.log('Load your polyfills')

Math.log2 = num => Math.log(num) / Math.log(2);
Math.cosh = num => (Math.exp(num) + Math.exp(-num)) / 2;
Math.tanh = num => {
  const a = Math.exp(+num),
    b = Math.exp(-num);
  return a == Infinity ? 1 : b == Infinity ? -1 : (a - b) / (a + b);
};
Math.sinh = num => (Math.exp(num) - Math.exp(-num)) / 2;
Math.sign = num => (num > 0) - (num < 0) || +num;
String.prototype.includes = includes;
String.prototype.repeat = repeat;
Object.assign = assign;
Object.Set = set;
Object.Map = map;
Array.from = from;

const hasStoragePermission = (() => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (exception) {
    return false;
  }
})();
if ('localStorage' in window && hasStoragePermission) {
  window.safeStorage = window.localStorage;
} else {
  window.safeStorage = {
    _data: {},
    setItem: function(id, val) {
      return (this._data[id] = String(val));
    },
    getItem: function(id) {
      return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
    },
    removeItem: function(id) {
      return delete this._data[id];
    },
    clear: function() {
      return (this._data = {});
    },
  };
}
